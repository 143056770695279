//import { createRouter, createWebHistory } from 'vue-router'
import { createRouter, createWebHashHistory } from 'vue-router'

const router = createRouter({
  history: createWebHashHistory(), // hash模式：createWebHashHistory，history模式：createWebHistory
 //history: createWebHistory(), // hash模式：createWebHashHistory，history模式：createWebHistory
  routes: [
    {
      name:'home',
      path: '/',
      component: () => import(/*webpackChunkName:'index'*/'@/page/Station'),
    },
    {
      name:'set',
      path: '/set',
      component: () => import(/*webpackChunkName:'index'*/'@/page/Config'),
    },
  ]
})

export default router
