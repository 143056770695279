<template>
  <router-view>
  </router-view>
</template>

<script>


export default {
  name: 'App',
  components: {

  },
  setup(){

  }
}
</script>

<style lang="less">
@import "./app.less";
</style>
