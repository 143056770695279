import { createApp,h } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'

import { plugin } from 'echarts-for-vue';
import * as echarts from 'echarts';

//import {WebSocketClient} from "@/utils/websocket";


const app = createApp(App)
      //app.config.globalProperties.$socket = new WebSocketClient('1');
      //app.config.globalProperties.$socket.initWebSocket()
      app.use(ElementPlus);
      app.use(router);
app.use(plugin, { echarts, h });
app.mount('#app')
/*router.beforeEach((to,from,next) => {

})*/

const debounce = (fn, delay) => {
      let timer = null;
      return function () {
            let context = this;
            let args = arguments;
            clearTimeout(timer);
            timer = setTimeout(function () {
                  fn.apply(context, args);
            }, delay);
      }
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
      constructor(callback) {
            callback = debounce(callback, 16);
            super(callback);
      }
}

